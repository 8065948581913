var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","elevation":"10","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-action',[_c('select-locale')],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.changeLang")))])],1),(
          this.$route.name == 'Map' ||
            this.$route.name == 'Records' ||
            this.$route.name == 'Player'
        )?_c('v-list-item',[_c('v-list-item-action',[_c('v-switch',{attrs:{"dense":"","inset":""},model:{value:(_vm.$store.state.showCps),callback:function ($$v) {_vm.$set(_vm.$store.state, "showCps", $$v)},expression:"$store.state.showCps"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.showCps")))])],1):_vm._e(),_c('v-list-item',[_c('v-list-item-action',[_c('v-switch',{attrs:{"dense":"","inset":""},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.darkMode")))])],1),_c('v-list-item',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-list-item-action',[_c('v-select',{attrs:{"dense":"","label":_vm.setDefaultRowsPerPageText,"items":_vm.items},model:{value:(_vm.$store.state.rowsPerPage),callback:function ($$v) {_vm.$set(_vm.$store.state, "rowsPerPage", $$v)},expression:"$store.state.rowsPerPage"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t("message.close"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }