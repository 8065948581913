
















































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import VueAxios from "vue-axios";
import { TimeFormat } from "../TimeFormat";
import { MPStyle } from "@tomvlk/ts-maniaplanet-formatter/";
import { Track } from "./Maps.vue";
import { Player } from "./Players.vue";
import dayjs from "dayjs";

Vue.use(VueAxios, axios);

interface Record {
  id: number;
  created_at: string;
  updated_at: string;
  map_id: number;
  track: Track;
  player_id: number;
  player: Player;
  score: string;
  checkpoints: string;
}

@Component
export default class Records extends Vue {
  search = "";
  loading = true;
  records: Record[] = [];
  get playerText() {
    return this.$t("message.player");
  }
  get updatedAtText() {
    return this.$t("message.updatedAt");
  }
  get searchText() {
    return this.$t("message.search");
  }
  get loadingText() {
    return this.$t("message.loading");
  }
  get headers() {
    return [
      // {
      //   text: "Id",
      //   align: "start",
      //   value: "id"
      // },
      { text: "Map", value: "track.name", sortable: false },
      { text: this.playerText, value: "player.nickname", sortable: false },
      { text: "Score", value: "score" },
      this.$store.state.showCps && {
        text: "Checkpoints",
        value: "checkpoints"
      },
      // { text: "Created At", value: "created_at" },
      { text: this.updatedAtText, value: "updated_at" }
    ];
  }
  get footerProps() {
    return {
      "items-per-page-options": [5, 10, 15, 50, 100],
      "items-per-page-text": this.$t("message.rowsPerPage")
    };
  }

  mounted() {
    this.refresh();
  }
  refresh() {
    this.loading = true;
    Vue.axios
      .get(`${this.$store.state.config.apiSite}/records/`)
      .then(resp => {
        this.records = resp.data;
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }
  tmStyle(nickname: string): string {
    return MPStyle(nickname);
  }
  toTmTime(score: string): string {
    return TimeFormat(+score);
  }
  formatDate(updatedAt: string): string {
    return dayjs(updatedAt).format("DD/MM/YYYY | HH:mm");
  }
}
