






































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Track } from "@/views/Maps.vue";
import { MPStyle } from "@tomvlk/ts-maniaplanet-formatter";
import { TimeFormat } from "@/TimeFormat";

@Component
export default class MapInfosCard extends Vue {
  @Prop() private track!: Track;
  @Prop() private loadingTrack!: boolean;

  tmStyle(name: string): string {
    return MPStyle(name);
  }
  toTmTime(score: string): string {
    return TimeFormat(+score);
  }
}
