var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"maps"},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.searchText,"single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"footer-props":_vm.footerProps,"items":_vm.maps,"items-per-page":_vm.$store.state.rowsPerPage,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.loadingText,"dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","to":'/map/' + item.id}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tmStyle(item.name))}})])]}},{key:"item.time_author",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.toTmTime(item.time_author))}})]}},{key:"item.mx_id",fn:function(ref){
var item = ref.item;
return [(item.mx_id)?_c('v-btn',{attrs:{"href":_vm.mxLink(item.mx_id),"target":"blank","small":"","text":"","fab":""}},[(_vm.$store.state.config.mxOrTmx == 'mx')?_c('img',{attrs:{"src":require("../assets/mx_100.png"),"width":"30px"}}):_c('img',{attrs:{"src":require("../assets/tmx_100.png"),"width":"30px"}})]):_vm._e()]}},{key:"item.uid",fn:function(ref){
var item = ref.item;
return [(item.mx_id != null)?_c('img',{attrs:{"width":"50px","src":_vm.mxThumb(item.mx_id),"alt":"map thumbnail"}}):_c('span',[_vm._v("Not on mx")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }