







































































import { Component, Emit, Vue } from "vue-property-decorator";
import axios from "axios";
import VueAxios from "vue-axios";
import { MPStyle } from "@tomvlk/ts-maniaplanet-formatter/";
import { TimeFormat } from "../TimeFormat";
import dayjs from "dayjs";
import { Track } from "./Maps.vue";
import KarmaCard from "@/components/KarmaCard.vue";
import MapInfosCard from "@/components/MapInfosCard.vue";
import MxInfosCard from "@/components/MxInfosCard.vue";

Vue.use(VueAxios, axios);

export interface TrackRecord {
  id: number;
  rank: number;
  player_id: number;
  player_nickname: string;
  score: string;
  updated_at: string;
}

@Component({
  components: {
    KarmaCard,
    MapInfosCard,
    MxInfosCard
  }
})
export default class TrackRecords extends Vue {
  search = "";
  loading = true;
  loadingTrack = true;
  showCps = false;

  get searchText() {
    return this.$t("message.search");
  }
  get loadingText() {
    return this.$t("message.loading");
  }
  get rankText() {
    return this.$t("message.rank");
  }
  get updatedAtText() {
    return this.$t("message.updatedAt");
  }
  get showCpsState() {
    return this.$store.state.showCps;
  }
  set showCpsState(value: boolean) {
    this.$store.commit("SET_SHOWCPS", value);
  }

  get headers() {
    return [
      { text: this.rankText, align: "start", value: "rank" },
      { text: "Player", value: "player_nickname", sortable: false },
      { text: "Score", value: "score" },
      { text: this.updatedAtText, value: "updated_at" },
      this.$store.state.showCps && {
        text: "Checkpoints Times",
        value: "checkpoints"
      }
    ];
  }
  get footerProps() {
    return {
      "items-per-page-options": [5, 10, 15, 50, 100, -1],
      "items-per-page-text": this.$t("message.rowsPerPage")
    };
  }

  @Emit("trackname")
  emitTrackName() {
    return this.track.name;
  }

  /* eslint-disable @typescript-eslint/camelcase */
  //unknown object value because vuejs will warn if we don't set a value
  track: Track = {
    id: 0,
    uid: "unknown",
    name: "unknown",
    author_login: "unknown",
    environment: "Stadium",
    num_checkpoints: 10,
    time_author: "43808",
    mx_id: null
  };
  trackRecords: TrackRecord[] = [];
  trackKarma = 0;
  trackKarmaOfFive = 0;
  trackKarmaCount = 0;

  mounted() {
    this.refresh();
    this.loadingTrack = true;
    Vue.axios
      .get(
        `${this.$store.state.config.apiSite}/tracks/${this.$route.params.id}`
      )
      .then(resp => {
        this.track = resp.data;
        this.emitTrackName();
        this.loadingTrack = false;
      })
      .catch(() => (this.loadingTrack = false));
  }
  refresh() {
    this.loading = true;
    Vue.axios
      .get(
        `${this.$store.state.config.apiSite}/records/track/${this.$route.params.id}`
      )
      .then(resp => {
        this.trackRecords = resp.data;
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }
  copyUid() {
    navigator.clipboard.writeText(this.track.uid);
  }
  tmStyle(name: string): string {
    return MPStyle(name);
  }
  toTmTime(score: string): string {
    return TimeFormat(+score);
  }
  formatDate(updatedAt: string): string {
    return dayjs(updatedAt).format("DD/MM/YYYY | HH:mm");
  }
  mxThumb(mxId: number): string {
    if (this.$store.state.config.mxOrTmx == "mx")
      return "https://tm.mania-exchange.com/maps/thumbnail/" + mxId;
    else {
      return "https://trackmania.exchange/maps/thumbnail/" + mxId;
    }
  }
}
