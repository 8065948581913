















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Track } from "@/views/Maps.vue";

@Component
export default class MxInfosCard extends Vue {
  @Prop() private track!: Track;

  mxLink(mxId: number): string {
    if (this.$store.state.config.mxOrTmx == "mx")
      return "https://tm.mania-exchange.com/maps/" + mxId;
    else {
      return "https://trackmania.exchange/maps/" + mxId;
    }
  }

  mxDownloadMap(mxId: number): string {
    if (this.$store.state.config.mxOrTmx == "mx")
      return "https://tm.mania-exchange.com/maps/download/" + mxId;
    else {
      return "https://trackmania.exchange/maps/download" + mxId;
    }
  }
}
