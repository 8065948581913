var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.searchText,"single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2",on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"footer-props":_vm.footerProps,"items":_vm.records,"items-per-page":_vm.$store.state.rowsPerPage,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.loadingText,"dense":""},scopedSlots:_vm._u([{key:"item.track.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","to":'/map/' + item.track.id}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tmStyle(item.track.name))}})])]}},{key:"item.player.nickname",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","to":'/player/' + item.player.id}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tmStyle(item.player.nickname))}})])]}},{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.toTmTime(item.score))}})]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatDate(item.updated_at))}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }