























import Vue from "vue";
import Component from "vue-class-component";
import CountryFlag from "vue-country-flag";

@Component({
  components: {
    CountryFlag
  }
})
export default class SelectLocale extends Vue {
  langs = ["fr", "en"];

  get locale() {
    if (this.$i18n.locale === "en") return "gb";
    else if (this.$i18n.locale === "fr") return "fr";
    else return "gb";
  }

  get filteredLangs() {
    const filteredLangs: string[] = [];
    this.langs.map(lang => {
      if (lang !== this.$i18n.locale) filteredLangs.push(lang);
    });
    return filteredLangs;
  }

  country(lang: string) {
    if (lang === "en") return "gb";
    else if (lang === "fr") return "fr";
    else return "gb";
  }

  changeCountry(lang: string) {
    this.$i18n.locale = lang;
    //persist in localstorage
    localStorage.Lang = lang;
  }
}
